import React from "react"
import styles from "./ecomm-css-module.module.scss"
import { InView } from "react-intersection-observer"

import APPAREL1 from "../assets/ecomm/APPAREL1.png"
import Electronics1 from "../assets/ecomm/Electronics1.png"
import HORECA1 from "../assets/ecomm/HORECA1.png"
import FMCG1 from "../assets/ecomm/FMCG1.png"
import APPAREL2 from "../assets/ecomm/APPAREL2.png"
import Electronics2 from "../assets/ecomm/Electronics2.png"
import HORECA2 from "../assets/ecomm/HORECA2.png"
import FMCG2 from "../assets/ecomm/FMCG2.png"
import HOME1 from "../assets/ecomm/HOME1.png"
import HOME2 from "../assets/ecomm/HOME2.png"
import FW1 from "../assets/ecomm/Footwear1.png"
import FW2 from "../assets/ecomm/Footwear2.png"

// import buyOnSolv from "../../static/buyOnSolv.png"
// import sellOnSolv from "../../static/sellOnSolv.png"
import solvScore from "../../static/solvScore.png"
import googleplay from "../../static/googleplay.png"
import buyOnSolvVid from "../assets/videos/Buy on SOLV video.mp4"
import buyOnSolvVidThumb from "../assets/Videos Thumbnails/Buy on solv.png"
import sellOnSolvVid from "../assets/videos/SELL_ON_SOLV_video.mp4"
import sellOnSolvVidThumb from "../assets/Videos Thumbnails/Sell on Solv.png"

import { Layout, BannerSlickWrapper, Testimonial, SEO } from "../components"
import Img from "gatsby-image"
import { Link, useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import CustomCarouselArrow from "../components/customCarouselArrow/customCarouselArrow"

const Ecommerce = () => {
  const categoriesList = [
    {
      src: FMCG1,
      srcOnHover: FMCG2,
      alt: "FMCG",
    },
    {
      src: HORECA1,
      srcOnHover: HORECA2,
      alt: "HoReCa",
    },
    {
      src: APPAREL1,
      srcOnHover: APPAREL2,
      alt: "Apparel",
    },
    {
      src: Electronics1,
      srcOnHover: Electronics2,
      alt: "Electronics",
    },
    {
      src: HOME1,
      srcOnHover: HOME2,
      alt: "HomeFurnishings",
    },
    {
      src: FW1,
      srcOnHover: FW2,
      alt: "Footwear",
    }
  ]

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    accessibility: true,
    focusOnSelect: false,
    draggable: true,
    nextArrow: <CustomCarouselArrow type="Next" />,
    prevArrow: <CustomCarouselArrow type="Prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          accessibility: false,
          dots: false,
        },
      },
    ],
  }

  const data = useStaticQuery(graphql`
    query {
      allFile(
        sort: { fields: name, order: ASC }
        filter: { relativeDirectory: { eq: "logossme" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  const buyerImages = data.allFile.edges.filter(ele =>
    ele.node.name.includes("buyer_")
  )

  const sellerImages = data.allFile.edges.filter(ele =>
    ele.node.name.includes("seller_")
  )

  function playPause(id, state) {
    let vid = document.getElementById(id)
    if (state) {
      vid.play()
    } else {
      vid.pause()
    }
  }

  function handleClickGooglePlay() {
    window.open(
      "https://play.google.com/store/apps/details?id=com.solv.commerce&referrer=utm_source%3Dwebsite%26utm_medium%3DhomePageCtaButton%26utm_term%3Dinstall"
    )
  }

  return (
    <div>
      {/* <Navbar/> */}
      <Layout>
      <SEO 
      title="Solv - B2B ecommerce |Buy and sell online| FMCG | Horeca" 
      description="Solv is a B2B ecommerce marketplace for SMEs. It gives retailers access to verified sellers, a wide product range, best prices, free credit & doorstep delivery."
      />
        <section className={styles.container}>
          <div className={styles.about}>
            {/* <img src={abtlogo} alt="logo" /> */}
            <div className={styles.imageContainer}>
              <BannerSlickWrapper page="commerce" />
            </div>
            <div className={styles.growthPlatform}>
              <div className={[styles.heading, "heading1SemiBold"].join(" ")}>
                India’s trusted B2B marketplace for small business
              </div>
              <div className={styles.description}>
                The Solv B2B marketplace helps retailers and other businesses
                source high quality products at the best price from suppliers
                across India. Suppliers in turn can showcase their products,
                establish trust and find new buyers. Download the app & register
                to get started.
              </div>
              <a onClick={handleClickGooglePlay}>
                <img
                  className={styles.googleplay}
                  alt="play store link"
                  src={googleplay}
                />
                <div className={styles.googleTitle}>Only in India</div>
              </a>
            </div>
          </div>
          <section className={styles.buyOnSolv}>
            <div className={styles.solvSell}>
              <div class={styles.buysellHeading}>
                <div
                  className={[styles.centerHeading, "heading1Medium"].join(" ")}
                >
                  Buy on Solv
                </div>
                <div className={styles.title}>
                  Discover new suppliers and grow your margins
                </div>
              </div>
            </div>
            <div Class={styles.buySolvDivision}>
              <div className={styles.buyText}>
                <div className={styles.solvSellBuy}>
                  <div class={styles.buysellHeading}>
                    <div
                      className={[styles.centerHeading, "heading1Medium"].join(
                        " "
                      )}
                    >
                      Buy on Solv
                    </div>
                    <div className={styles.title}>
                      Discover new suppliers and grow your margins
                    </div>
                  </div>
                </div>

                <div className={styles.details}>
                  Buying for your business has never been this easy. Solv gives
                  you access to quality products from verified suppliers at the
                  best price. Order from the comfort of your store or business
                  and receive goods on time, right at your doorstep. Take
                  advantage of our Pay Later credit option to finance your
                  orders. Ordering on Solv is quick and convenient, saving you
                  time so you can focus on growing your business.
                </div>
                <div className={styles.homeCard}>
                  <div className={styles.heading}>Buy now, Pay later</div>
                  <div className={styles.details}>
                    Buyers on Solv can take advantage of invoice financing
                    through the Buy Now, Pay Later credit facility. Buy Now, Pay
                    Later offers short term, collateral free credit that can be
                    used by buyers against multiple invoices on the app.
                  </div>
                  {/* <div className={styles.hyperlink}>Know More </div> */}
                </div>
              </div>
              <InView
                as="div"
                threshold="0.5"
                onChange={(inView, entry) => playPause("buyOnSolvVid", inView)}
              >
                <div>
                  <video
                    id="buyOnSolvVid"
                    onClick={() => playPause("buyOnSolvVid")}
                    loop
                    autoPlay
                    muted
                    playsInline
                    poster={buyOnSolvVidThumb}
                  >
                    <source src={buyOnSolvVid} type="video/mp4" />
                  </video>
                  {/* <img src={buyOnSolv} alt="buyOnSolv" /> */}
                </div>
              </InView>
            </div>

            <div className={styles.solvBusiness}>
              <div className={styles.heading}>
                We enable several businesses streamline their procurement
                process
              </div>

              <div className={styles.logoSlide}>
                <Slider {...settings}>
                  {buyerImages.map((value, index) => {
                    return (
                      <Img
                        key={value.node.id}
                        fluid={value.node.childImageSharp.fluid}
                        alt={`buyer image ${index}`}
                      />
                    )
                  })}
                </Slider>
              </div>
              {/* <div className={styles.buyOnSolvLogo}> */}
              {/* {buyerImages.map((value, index) => {
                  return (
                    <Img
                      key={value.node.id}
                      fluid={value.node.childImageSharp.fluid}
                      alt={`buyer image ${index}`}
                    />
                  )
                })} */}
              {/* <img src={akshayaPatra} alt="akshayaPatra" />
                <img src={kapoorKitchen} alt="kapoorKitchen" />
                <img src={richBite} alt="richBite" />
                <img src={goong} alt="goong" /> */}
              {/* </div> */}
            </div>

            <div className={styles.solvSell}>
              <div className={styles.sellHeading}>
                <div
                  className={[styles.centerHeading, "heading1Medium"].join(" ")}
                >
                  Sell on Solv
                </div>
                <div className={styles.title}>
                  Reach new customers and grow your sales
                </div>
              </div>
            </div>
            <div className={styles.sellSolvDivision}>
              <InView
                as="div"
                threshold="0.5"
                onChange={(inView, entry) => playPause("sellOnSolvVid", inView)}
              >
                <div>
                  <video
                    id="sellOnSolvVid"
                    onClick={() => playPause("sellOnSolvVid")}
                    loop
                    autoPlay
                    muted
                    playsInline
                    poster={sellOnSolvVidThumb}
                  >
                    <source src={sellOnSolvVid} type="video/mp4" />
                  </video>
                  {/* <img src={sellOnSolv} alt="buyOnSolv" /> */}
                </div>
              </InView>
              <div>
                <div className={styles.solvSellBuy}>
                  <div class={styles.sellHeading}>
                    <div
                      className={[styles.centerHeading, "heading1Medium"].join(
                        " "
                      )}
                    >
                      Sell on Solv
                    </div>
                    <div className={styles.reachCustomer}>
                      Reach new customers and grow your sales
                    </div>
                  </div>
                </div>

                <div className={styles.sellText}>
                  <div className={styles.details}>
                    Reach new markets and buyers across India. Showcase your
                    products on the Solv app and have buyers place their orders
                    online. Be assured of on-time delivery and payment.
                  </div>
                  {/* <div className={styles.homeCard}>
                  <div className={styles.heading}>Early settlement</div>
                  <div className={styles.details}>
                    Sellers can take advantage of Solv’s early settlement option
                    which ensures that sellers receive payment immediately upon
                    delivery of goods against a small fee. Sellers benefit from
                    the interest earned on payments received early.
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className={styles.bottomContainer}>
          <div className={styles.solvBusiness}>
            <div className={styles.heading}>
              We help several brands reach retailers across India
            </div>
            <div className={styles.logoSlide}>
              <Slider {...settings}>
                {sellerImages.map((value, index) => {
                  return (
                    <Img
                      key={value.node.id}
                      fluid={value.node.childImageSharp.fluid}
                      alt={`seller image ${index}`}
                    />
                  )
                })}
              </Slider>
            </div>
          </div>
          <div className={styles.categoriesSection}>
            <div className={[styles.heading, "heading1Medium"].join(" ")}>
              Categories we serve
            </div>
            <div className={styles.description}>
              You can buy and sell products from across the following categories
              on Solv today. Stay tuned for new categories coming soon.
            </div>
            <div className={styles.imagesContainer}>
              {categoriesList.map(i => (
                <div
                  className={[styles.image, styles[i.alt]].join(" ")}
                  onMouseOver={e => {
                    i.srcOnHover &&
                      (e.currentTarget.children[0].src = i.srcOnHover)
                  }}
                  onMouseOut={e => {
                    i.src && (e.currentTarget.children[0].src = i.src)
                  }}
                >
                  <img src={i.src} alt={i.alt} />
                </div>
              ))}
            </div>
          </div>
          {/* <div className={styles.solvScoreSectionBG}>
            <div className={[styles.scoreHeadings, "heading1Medium"].join(" ")}>
              Solv Score
            </div>
            <div className={styles.solvScoreSection}>
              <div className={styles.scoreGap}>
                <div className={styles.scoreHeading}>Solv Score</div>
                <div className={styles.details}>
                  Buyers and sellers on the Solv platform are distinguished by
                  their Solv Score. The Solv score of a business is based on
                  several parameters. These parameters include traditional data
                  like one’s credit bureau score, several forms of alternate
                  data, as well as behavioural / operational data based on one’s
                  transaction history on the Solv platform. The Solv Score
                  serves as an alternate credit score helping SMEs get easier
                  and faster access to credit and also helps businesses
                  establish trust on the Solv platform.
                </div>
              </div>
              <img src={solvScore} alt="solvScore" />
            </div>
          </div> */}

          <div className={styles.solvScoreSection}>
            <img src={solvScore} alt="solvScore" />
            <div className={styles.scoreGap}>
              <div
                className={[styles.scoreHeadings, "heading1Medium"].join(" ")}
              >
                Solv Score
              </div>
              <div className={styles.details}>
                Buyers and sellers on the Solv platform are distinguished by
                their Solv Score. The Solv score of a business is based on
                several parameters. These parameters include traditional data
                like one’s credit bureau score, several forms of alternate data,
                as well as behavioural / operational data based on one’s
                transaction history on the Solv platform. The Solv Score serves
                as an alternate credit score helping SMEs get easier and faster
                access to credit and also helps businesses establish trust on
                the Solv platform.
              </div>
            </div>
          </div>

          <div className={styles.solvLoyaltySectionBG}>
            {/* <div className={styles.loyaltyHeadings}>Loyalty Program</div>
            <div className={styles.solvLoyaltySection}>
              <img src={solvScore} alt="solvScore" />
              <div className={styles.loyaltyGap}>
                <div className={styles.loyaltyHeading}>Loyalty Program</div>
                <div className={styles.description}>
                  Buyers and sellers on the Solv platform are distinguished by
                  their Solv Score. The Solv score of a business is based on
                  several parameters. These parameters include traditional data
                  like one’s credit bureau score, several forms of alternate
                  data, as well as behavioural / operational data based on one’s
                  transaction history on the Solv platform. The Solv Score
                  serves as an alternate credit score helping SMEs get easier
                  and faster access to credit and also helps businesses
                  establish trust on the Solv platform.
                </div>
                <div className={styles.link}>Know More ></div>
              </div>
            </div> */}
          </div>
          <div>
            <Testimonial></Testimonial>
          </div>
        </section>
        {/* <Footer /> */}
      </Layout>
    </div>
  )
}

export default Ecommerce
